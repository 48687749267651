import React, { useState } from 'react';
import '../styles/ContactMe.css';
import { sendEmail } from '../components/SendEmailJS';

const ContactMe = () => {
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    from_phone: '',
    email_subject: '',
    email_message: ''
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Start loading animation
    sendEmail(formData)
      .then(() => {
        alert('Email sent successfully!');
        setFormData({
          from_name: '',
          from_email: '',
          from_phone: '',
          email_subject: '',
          email_message: ''
        });
      })
      .catch(() => {
        alert('Failed to send email. Please try again later.');
      })
      .finally(() => setLoading(false));  // Stop loading animation
  };

  return (
    <div className="contact-form-container">
      <h2>Contact Me</h2>
      <form onSubmit={handleSubmit} className="contact-form">
        <input
          type="text"
          name="from_name"
          placeholder="Nom et Prénom"
          value={formData.from_name}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="from_email"
          placeholder="Adresse e-mail"
          value={formData.from_email}
          onChange={handleChange}
          required
        />
        <input
          type="tel"
          name="from_phone"
          placeholder="Mobile Number"
          value={formData.from_phone}
          onChange={handleChange}
        />
        <input
          type="text"
          name="email_subject"
          placeholder="Objet de mail"
          value={formData.email_subject}
          onChange={handleChange}
        />
        <textarea
          name="email_message"
          placeholder="Votre message.."
          value={formData.email_message}
          onChange={handleChange}
          required
        />
        {/* <button type="submit" className="send-button">Envoyer</button> */}

        <button type="submit" className="send-button" disabled={loading}>
          {loading ? 'Envoi en cours...' : 'Envoyer'}
        </button>
      </form>
      {loading && <div className="loading-overlay">Envoi en cours...</div>}
    </div>
  );
};

export default ContactMe;
