import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './views/Home';
import Ressources from './views/Ressources';
import ContactMe from './views/ContactMe';
import RapportIsn2019 from './views/RapportIsn2019';
import Hackathon2023 from './views/Hackathon2023';
import Hackathon2024 from './views/Hackathon2024';
import ReferentielRpa from './views/ReferentielRpa';
import ProjetIjss from './views/ProjetIjss';
import Rapport2020 from './views/Rapport2020';
import Rapport2021 from './views/Rapport2021';
import Rapport2022 from './views/Rapport2022';
import Rapport2023 from './views/Rapport2023';
import Cv2024 from './views/Cv2024';
import Portfolio from './views/Portfolio';
import './App.css'

const App = () => (
  <Router>
    <div className='root-application'>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/ressources" element={<Ressources />} />
        <Route path="/contact-me" element={<ContactMe />} />
        <Route path="/ppp-2024/rapport-isn-2019" element={<RapportIsn2019 />} />
        <Route path="/ppp-2024/hackathon-2023" element={<Hackathon2023 />} />
        <Route path="/ppp-2024/hackathon-2024" element={<Hackathon2024 />} />
        <Route path="/ppp-2024/referentiel-rpa" element={<ReferentielRpa />} />
        <Route path="/ppp-2024/projet-ijss" element={<ProjetIjss />} />
        <Route path="/ppp-2024/rapport-2020" element={<Rapport2020 />} />
        <Route path="/ppp-2024/rapport-2021" element={<Rapport2021 />} />
        <Route path="/ppp-2024/rapport-2022" element={<Rapport2022 />} />
        <Route path="/ppp-2024/rapport-2023" element={<Rapport2023 />} />
        <Route path="/ppp-2024/cv-2024" element={<Cv2024 />} />
        <Route path="/ppp-2024/portfolio" element={<Portfolio />} />
      </Routes>
      <Footer />
    </div>
  </Router>
);

export default App;
