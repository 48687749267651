

import React, { useState } from 'react';
import '../styles/Hackathon2023.css';

const Hackathon2023 = () => {
    const pdfDocuments = [
        { 'filename': 'Hackathon 2024' },
    ];
    const [pdfFilename, setPdfFilename] = useState(pdfDocuments[0].filename);
    const [pdfUrl, setPdfUrl] = useState('/assets/' + pdfFilename + '.pdf');

    const handleDocumentClick = (filename) => {
        setPdfFilename(filename);
        setPdfUrl('/assets/' + filename + '.pdf');
    };

    return (
        <div className="page-container">

            <div className='page-header'>
                <div className='header-element h1'>
                    {pdfFilename}
                </div>
                <div className='header-element link'>
                    <a href={pdfUrl} download>
                        <button>Télécharger le document</button>
                    </a>
                </div>
            </div>

            <div className='page-body'>
                <div className='documents-list'>
                    {pdfDocuments.map(doc => (
                        <div
                            key={doc.filename}
                            className='document-item'
                            onClick={() => handleDocumentClick(doc.filename)}
                        >
                            {doc.filename}
                        </div>
                    ))}
                </div>

                <div className="pdf-container">
                    <iframe
                        src={pdfUrl}
                        width="100%"
                        height="100%"
                        style={{ border: 'none' }}
                        title={pdfFilename}
                    >
                        Votre navigateur ne supporte pas PDFs. 
                        Veuillez changer de navigateur ou <a href={pdfUrl}> téléchargez le document</a>.
                    </iframe>
                </div>
            </div>
        </div>
    );
};

export default Hackathon2023;
