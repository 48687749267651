import React from 'react';
import '../styles/Home.css';

const Home = () => (
  <div className='home-container'>
    <br></br>
    <br></br>
    <h1>Welcome to My Portfolio</h1>
    <p></p>
    <p className='custom-message'>Cette page est en cours de developpement..</p>
  </div>
);

export default Home;
