import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';

const Header = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const location = useLocation();

    const headerBtnList = [{
            "name" : "Home",
            "href" : "/home",
            "icon" : "",
        }, { 
            "name" : "Ressources",
            "href" : "/ressources",
            "icon" : "",
        }, {
            "name" : "Resume",
            "href" : "/ppp-2024/cv-2024",
            "icon" : "",
        }, {
            "name" : "Contact me",
            "href" : "/contact-me",
            "icon" : "",
        }, {
            "name" : "Portfolio",
            "href" : "/portfolio",
            "icon" : "",
        }
    ];

    

    return (
        <header>
            <div className="logo">
                <a href="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <h1>Cristian TIRCHE</h1>
                </a>
            </div>
            <nav>
                <ul className={`nav-links ${openMenu ? 'active' : ''}`}>
                    {headerBtnList.map((button, index) => (
                        // Determine if the link should be active based on the current URL
                        // const isActive = (path) => location.pathname === path;
                        <li>
                            <Link 
                            to={button.href} 
                            className={(location.pathname === button.href) || 
                                (location.pathname === '/' && button.name === 'Home')
                                ? 'active' : '' }>
                                {button.name}
                            </Link>
                        </li>
                    ))}
                    
                </ul>

                <button 
                    className="menu-toggle" 
                    onClick={() => setOpenMenu(!openMenu)}
                >
                    ☰
                </button>
            </nav>
        </header>
    );
};

export default Header;
