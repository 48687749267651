
import React, { useState, useEffect } from 'react';
import '../styles/RapportIsn2019.css';

const Rapport2023 = () => {
    const pdfFilename = 'Rapport d\'alternance Cristian Tirche 2023.pdf';
    const documentTitle = 'Rapport d\'alternance Cristian Tirche 2023';
    const [pdfExists, setPdfExists] = useState(true);
    const [renderError, setRenderError] = useState(false);
    const pdfUrl = '/assets/'+pdfFilename;

    useEffect(() => {
        const checkPdfExists = async () => {
            try {
                const response = await fetch(pdfUrl, { method: 'HEAD' });
                if (response.ok) {
                    setPdfExists(true);
                } else {
                    setPdfExists(false);
                }
            } catch (error) {
                setPdfExists(false);
            }
        };
        checkPdfExists();
    }, [pdfUrl]);

    return (
        <div className="page-container">
            <div className='page-header'>
                <div className='header-element h1'>
                    {documentTitle}
                </div>
                <div className='header-element link'>
                    {pdfExists && !renderError && (
                        <a href={pdfUrl} download>
                            <button>Télécharger le document</button>
                        </a>
                    )}
                </div>
            </div>

            <div className='page-body'>
                {pdfExists ? (
                <div className="pdf-container" style={{ height: '90vh' }}>
                    <iframe
                    src={pdfUrl}
                    width="100%"
                    height="84%"
                    style={{ border: 'none' }}
                    title={documentTitle}
                    // Handle errors by trying to catch iframe load errors manually
                    onLoad={() => setRenderError(false)}
                    onError={() => setRenderError(true)}
                    >
                    Votre navigateur ne supporte pas PDFs. 
                    Veuillez changer de navigateur ou <a href={pdfUrl}> téléchargez le document</a>.
                    </iframe>
                </div>
                ) : (
                <p>Fichier non trouvé.</p>
                )}
            </div>
        </div>
    );
};

export default Rapport2023;
