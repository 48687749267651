// ./src/components/SendEmailJS.js

import emailjs from 'emailjs-com';

// Configure the emailjs services
const SERVICE_ID = 'service_gmail';
const TEMPLATE_ID = 'template_5tmo8a8';
const USER_ID = 'r4zObwN4qfGPcmbOC';

/**
 * Sends an email using EmailJS.
 * @param {string} name - The name of the sender.
 * @param {string} email - The sender's email address.
 * @param {string} phone - The sender's phone number.
 * @param {string} subject - The subject of the email.
 * @param {string} message - The message content of the email.
 * @returns {Promise} - A promise that resolves if the email is sent successfully, rejects if there’s an error.
 */

export const sendEmail = ({ from_name, from_email, from_phone, email_subject, email_message }) => {
    const templateParams = {
        from_name,
        from_email,
        from_phone,
        email_subject,
        email_message,
    };

    return emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID)
        .then((response) => {
            console.log('Email sent successfully!', response.status, response.text);
            return response;
        })
        .catch((error) => {
            console.error('Failed to send email:', error);
            throw error;
        });
};