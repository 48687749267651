
import React, { useState, useEffect } from 'react';
import '../styles/RapportIsn2019.css';

const Rapport2022 = () => {
    const pdfFilename = 'Rapport Projet IJSS.pdf';
    const documentTitle = 'Rapport Projet IJSS';
    const [pdfExists, setPdfExists] = useState(true);
    const [renderError, setRenderError] = useState(false);
    const pdfUrl = '/assets/'+pdfFilename;

    useEffect(() => {
        const checkPdfExists = async () => {
            try {
                const response = await fetch(pdfUrl, { method: 'HEAD' });
                if (response.ok) {
                    setPdfExists(true);
                } else {
                    setPdfExists(false);
                }
            } catch (error) {
                setPdfExists(false);
            }
        };
        checkPdfExists();
    }, [pdfUrl]);

    return (
        <div className="page-container">
            <div className='page-header'>
                <div className='header-element h1'>
                    {documentTitle}
                </div>
                <div className='header-element link'>
                    {pdfExists && !renderError && (
                        <a href={pdfUrl} download>
                            <button>Télécharger le document</button>
                        </a>
                    )}
                </div>
            </div>

            <div className='page-body'>
                
            </div>
        </div>
    );
};

export default Rapport2022;
