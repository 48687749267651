import React from 'react';
import './Footer.css';

const Footer = () => (
    <footer>
        <div className="footer-content">
            <p>&copy; 2024 Cristian TIRCHE. All rights reserved.</p>
            <div className="social-links">
                <a href="https://www.linkedin.com/in/cristian-tirche/" 
                target="_blank" rel="noopener noreferrer">LinkedIn</a>
                <a href="https://github.com/Cristi571" 
                target="_blank" rel="noopener noreferrer">GitHub</a>
                <a href="mailto:cristian.tirche@gmail.com">Email</a>
            </div>
        </div>
    </footer>
);

export default Footer;