import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Ressources.css';

const Ressources = () => {
  return (
    <div className="ressources-page">
      <h1>Ressources</h1>
      <ul>
        <li><Link to="/ppp-2024/rapport-isn-2019">Rapport ISN 2019</Link></li>
        <li><Link to="/ppp-2024/hackathon-2023">Hackathon 2023</Link></li>
        <li><Link to="/ppp-2024/hackathon-2024">Hackathon 2024</Link></li>
        <li><Link to="/ppp-2024/referentiel-rpa">Référentiel RPA</Link></li>
        <li><Link to="/ppp-2024/projet-ijss">Projet IJSS</Link></li>
        <li><Link to="/ppp-2024/rapport-2020">Rapport 2020</Link></li>
        <li><Link to="/ppp-2024/rapport-2021">Rapport 2021</Link></li>
        <li><Link to="/ppp-2024/rapport-2022">Rapport 2022</Link></li>
        <li><Link to="/ppp-2024/rapport-2023">Rapport 2023</Link></li>
        <li><Link to="/ppp-2024/cv-2024">CV 2024</Link></li>
        <li><Link to="/ppp-2024/portfolio">Portfolio</Link></li>
      </ul>
    </div>
  );
};

export default Ressources;